#autocomplete {
  z-index: 100;
  border: 1px solid;
  position: absolute;
  overflow: hidden;
}

#autocomplete ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#autocomplete li {
  color: #000;
  cursor: default;
  white-space: pre;
  zoom: 1;
  background: #fff;
}

html.js fieldset.collapsed {
  height: 1em;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
}

html.js fieldset.collapsed .fieldset-wrapper {
  display: none;
}

fieldset.collapsible {
  position: relative;
}

fieldset.collapsible .fieldset-legend {
  display: block;
}

.form-textarea-wrapper textarea {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  display: block;
}

.resizable-textarea .grippie {
  cursor: s-resize;
  height: 9px;
  background: #eee 50% 2px no-repeat;
  border: 1px solid #ddd;
  border-top-width: 0;
  overflow: hidden;
}

body.drag {
  cursor: move;
}

.draggable a.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  text-decoration: none;
  overflow: hidden;
}

a.tabledrag-handle:hover {
  text-decoration: none;
}

a.tabledrag-handle .handle {
  height: 13px;
  width: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
}

a.tabledrag-handle-hover .handle {
  background-position: 6px -11px;
}

div.indentation {
  float: left;
  height: 1.7em;
  width: 20px;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
}

.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

table.sticky-header {
  background-color: #fff;
  margin-top: 0;
}

.progress .bar {
  background-color: #fff;
  border: 1px solid;
}

.progress .filled {
  height: 1.5em;
  width: 5px;
  background-color: #000;
}

.progress .percentage {
  float: right;
}

.ajax-progress {
  display: inline-block;
}

.ajax-progress .throbber {
  float: left;
  height: 15px;
  width: 15px;
  margin: 2px;
}

.ajax-progress .message {
  padding-left: 20px;
}

tr .ajax-progress .throbber {
  margin: 0 2px;
}

.ajax-progress-bar {
  width: 16em;
}

.container-inline div, .container-inline label {
  display: inline;
}

.container-inline .fieldset-wrapper {
  display: block;
}

.nowrap {
  white-space: nowrap;
}

html.js .js-hide, .element-hidden {
  display: none;
}

.element-invisible {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}

.element-invisible.element-focusable:active, .element-invisible.element-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static !important;
}

.clearfix:after {
  content: ".";
  height: 0;
  clear: both;
  visibility: hidden;
  display: block;
}

* html .clearfix {
  height: 1%;
}

:first-child + html .clearfix {
  min-height: 1%;
}

ul.menu {
  text-align: left;
  border: none;
  list-style: none;
}

ul.menu li {
  margin: 0 0 0 .5em;
}

ul li.expanded {
  list-style-type: circle;
}

ul li.collapsed {
  list-style-type: disc;
}

ul li.leaf {
  list-style-type: square;
}

li.expanded, li.collapsed, li.leaf {
  margin: 0;
  padding: .2em .5em 0 0;
}

li a.active {
  color: #000;
}

td.menu-disabled {
  background: #ccc;
}

ul.inline, ul.links.inline {
  padding-left: 0;
  display: inline;
}

ul.inline li {
  padding: 0 .5em;
  list-style-type: none;
  display: inline;
}

.breadcrumb {
  padding-bottom: .5em;
}

ul.primary {
  border-collapse: collapse;
  height: auto;
  white-space: nowrap;
  border-bottom: 1px solid #bbb;
  margin: 5px;
  padding: 0 0 0 1em;
  line-height: normal;
  list-style: none;
}

ul.primary li {
  display: inline;
}

ul.primary li a {
  height: auto;
  background-color: #ddd;
  border: 1px solid #bbb;
  border-bottom-style: none;
  margin-right: .5em;
  padding: 0 1em;
  text-decoration: none;
}

ul.primary li.active a {
  background-color: #fff;
  border: 1px solid #bbb;
  border-bottom-color: #fff;
}

ul.primary li a:hover {
  background-color: #eee;
  border-color: #ccc #ccc #eee;
}

ul.secondary {
  border-bottom: 1px solid #bbb;
  margin: 5px;
  padding: .5em 1em;
}

ul.secondary li {
  border-right: 1px solid #ccc;
  padding: 0 1em;
  display: inline;
}

ul.secondary a {
  padding: 0;
  text-decoration: none;
}

ul.secondary a.active {
  border-bottom: 4px solid #999;
}

div.messages {
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid;
  margin: 6px 0;
  padding: 10px 10px 10px 50px;
}

div.status {
  border-color: #be7;
}

div.status, .ok {
  color: #234600;
}

div.status, table tr.ok {
  background-color: #f8fff0;
}

div.warning {
  border-color: #ed5;
}

div.warning, .warning {
  color: #840;
}

div.warning, table tr.warning {
  background-color: #fffce5;
}

div.error {
  border-color: #ed541d;
}

div.error, .error {
  color: #8c2e0b;
}

div.error, table tr.error {
  background-color: #fef5f1;
}

div.error p.error {
  color: #333;
}

div.messages ul {
  margin: 0 0 0 1em;
  padding: 0;
}

div.messages ul li {
  list-style-image: none;
}

fieldset {
  margin-bottom: 1em;
  padding: .5em;
}

form {
  margin: 0;
  padding: 0;
}

hr {
  height: 1px;
  border: 1px solid gray;
}

img {
  border: 0;
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
  border-bottom: 3px solid #ccc;
  padding-right: 1em;
}

tbody {
  border-top: 1px solid #ccc;
}

tr.even, tr.odd {
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: .1em .6em;
}

th.active img {
  display: inline;
}

td.active {
  background-color: #ddd;
}

.item-list .title {
  font-weight: bold;
}

.item-list ul {
  margin: 0 0 .75em;
  padding: 0;
}

.item-list ul li {
  margin: 0 0 .25em 1.5em;
  padding: 0;
}

.form-item, .form-actions {
  margin-top: 1em;
  margin-bottom: 1em;
}

tr.odd .form-item, tr.even .form-item {
  white-space: nowrap;
  margin-top: 0;
  margin-bottom: 0;
}

.form-item .description {
  font-size: .85em;
}

label {
  font-weight: bold;
  display: block;
}

label.option {
  font-weight: normal;
  display: inline;
}

.form-checkboxes .form-item, .form-radios .form-item {
  margin-top: .4em;
  margin-bottom: .4em;
}

.form-type-radio .description, .form-type-checkbox .description {
  margin-left: 2.4em;
}

input.form-checkbox, input.form-radio {
  vertical-align: middle;
}

.marker, .form-required {
  color: red;
}

.form-item input.error, .form-item textarea.error, .form-item select.error {
  border: 2px solid red;
}

.container-inline .form-actions, .container-inline.form-actions {
  margin-top: 0;
  margin-bottom: 0;
}

.more-link, .more-help-link {
  text-align: right;
}

.more-help-link a {
  padding: 1px 0 1px 20px;
}

.item-list .pager {
  clear: both;
  text-align: center;
}

.item-list .pager li {
  background-image: none;
  padding: .5em;
  list-style-type: none;
  display: inline;
}

.pager-current {
  font-weight: bold;
}

#autocomplete li.selected {
  color: #fff;
  background: #0072b9;
}

html.js fieldset.collapsible .fieldset-legend {
  padding-left: 15px;
}

.fieldset-legend span.summary {
  color: #999;
  margin-left: .5em;
  font-size: .9em;
}

tr.drag {
  background-color: ivory;
}

tr.drag-previous {
  background-color: #ffd;
}

.tabledrag-toggle-weight {
  font-size: .9em;
}

body div.tabledrag-changed-warning {
  margin-bottom: .5em;
}

tr.selected td {
  background: #ffc;
}

td.checkbox, th.checkbox {
  text-align: center;
}

.progress {
  font-weight: bold;
}

.progress .bar {
  background: #ccc;
  border-color: #666;
  border-radius: 3px;
  margin: 0 .2em;
}

.progress .filled {
  background: #0072b9;
}

/*# sourceMappingURL=index.819c4c0c.css.map */
